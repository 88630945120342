<template lang="pug">
	content-page
		div(slot="nav")
			nav
				.font-weight-bold.subtitle-1 {{$t('page.academy:heading')}}
				ul.list-unstyled
					li
						nuxt-link(:to="localePath({name:'academy'})") {{$t('page.academy:about')}}

				.font-weight-bold.subtitle-1  {{$t('page.academy:levels')}}
				ul.list-unstyled
					li(v-for="level in levels" v-bind:key="`${level.id}_${level.slug}`" v-if="level.isDefault")
						nuxt-link(:to="localePath({name:'academy-level', params: {level: level.slug}})") {{level.name}}

				.font-weight-bold.subtitle-1 {{$t('page.academy:keep.learning')}}
				ul.list-unstyled
					li(v-for="level in levels" v-bind:key="`${level.id}_${level.slug}`" v-if="!level.isDefault")
						nuxt-link(:to="localePath({name:'academy-level', params: {level: level.slug}})") {{level.name}}

		div.hidden-sm-and-down(slot="sidebar")
			insurance-ad.mb-6(url="/verzekering/reisverzekering")
			ad.mb-6
			snow-alarm.mb-6
			offers.mb-6

		div
			nuxt-child
</template>

<script>
import gql from 'graphql-tag';
import InsuranceAd from '@/components/insurance-ad';

const query = gql`
	query getAcademyLevels($user_id: ID) {
		levels: academyLevels {
			id
			name
			slug
			intro
			content {
				markdown
			}
			video
			isDefault
			chaptersCount
			lessonsCount
			progress(user_id: $user_id)
		}
	}
`;

export default {
	name: 'Academy',
	components: {
		InsuranceAd
	},
	scrollToTop: true,
	// eslint-disable-next-line @milqmedia/milq/page-has-ads, @milqmedia/milq/page-has-meta
	asyncData({ app, store }) {
		return app.$graphClient
			.query({
				query,
				variables: {
					user_id: store.state.user.id
				}
			})
			.then(({ data, errors }) => {
				if (errors) {
					throw errors[0];
				}
				return data;
			})
			.catch(error => {
				return app.httpError(500, null, 'academy-01', error);
			});
	},
	head() {
		return {
			...this.$structuredData()
		};
	}
};
</script>
